import React from 'react'

function Gre() {
  return (
    <div>
      <div>
        <div className="acth"></div>
        <h2 style={{ textAlign: "center", margin: "20px" }}>ACT</h2>
        <ul>
          <li>
            ACT is written by candidates who desire to pursue their
            undergraduate studies in the U.S. and other parts of the world.
          </li>
          <li>There is the ACT with writing and ACT without writing.</li>
          <li>ACT is currently registered and written in Nigeria.</li>
          <li>
            The Test is written five times in a year.{" "}
            <a href="https://www.act.org/">Read more</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Gre